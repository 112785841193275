"use strict";

import $ from "jquery";
import "jquery-match-height";

export default class MegaMenuItem {
  constructor() {
    let $navLevel1 = $(".nav-level1");

    var viewPortHeight =
      window.innerHeight -
      $("#header").outerHeight() -
      $(".site-header__megamenu--bottom").outerHeight(); // 194;//header + footer height;

    $navLevel1.map((i, ele) => {
      let $this = $(ele);
      //   let minHeight = 0;

      let $level2List = $(".nav-level2", $this).parent();
      let $level3List = $(".nav-level2 > ul", $this);

      $level3List.css("min-height", $level2List.innerHeight());

      //   $level3.map((i, ele) => {
      //     let $thisLevel3 = $(ele);

      //     if ($thisLevel3.innerHeight() > minHeight) {
      //       minHeight = $thisLevel3.innerHeight();
      //     }
      //   });
      //   if (minHeight > viewPortHeight) {
      //     minHeight = viewPortHeight;
      //   }
      //   $this.find(".min-height").css("min-height", minHeight);
      //   $this
      //     .find(".min-height")
      //     .find(".nav-level2 > ul")
      //     .css("min-height", minHeight);
      //   $(".min-height", $this).matchHeight();
    });

    const dynamicMenuPositioning = () => {
      if ($(window).width() > 1024) {
        let browserWidth = $(window).width();
        $(".site-header__megamenu.desktop-only").map((i, ele) => {
          if ($(ele).offset().left + $(ele).width() * 2 > browserWidth - 20) {
            $(ele).addClass("flip");
          }
        });
      }
    };

    $(window).on("load", (e) => {
      dynamicMenuPositioning();
    });

    $(window).on("resize", (e) => {
      dynamicMenuPositioning();
    });
  }
}
