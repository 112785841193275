'use strict';

import $ from 'jquery';

export default class ExhibitionAnchor {
  constructor($selector, selfInit = true) {
    this.$selector = $selector;
    this.$anchorNavButton = $(
      '.exhibition-anchor__nav__dropdown button',
      this.$selector
    );
    this.$anchorNav = $('.exhibition-anchor__nav', this.$selector);
    this.$anchorNavLinks = $('.exhibition-anchor__nav__list a', this.$selector);
    this.$anchorContent = $('.exhibition-anchor__content', this.$selector);
    this.$anchorContentInfoWrapper = $('.exhibition-anchor__content__info__wrapper', this.$selector);
    this.$anchorContentDetailItem = $(
      '.exhibition-anchor__content__detail--item',
      this.$selector
    );

    this.$headerHeight = $('.site-header').outerHeight();
    this.$anchorNavHeight = $('.exhibition-anchor__nav').outerHeight();
    this.$anchorNavPosition =
      this.$anchorNav.offset().top;
    this.$containerBottom =
      this.$anchorContent.offset().top + this.$selector.outerHeight();
    this.$anchorNavHeight = this.$anchorNav.outerHeight(true);

    if (selfInit) this.init();
  }

  init() {
    this.initLabelText();
    this.setupSticky();
    this.setupScrollActive();

    if (document.readyState === 'complete') {
      this.scrollToSection();
    } else {
      // Attach the load event handler if the document is not yet loaded
      $(window).on('load', () => {
        this.scrollToSection();
      });
    }

    $(window).on('load', () => {
      this.scrollToSection();
    });

    $(window).on('resize', () => {
      this.resizeReinit();
    });

    this.$anchorNavLinks.on('click', (e) => {
      e.preventDefault();

      this.setupAnchorLinks(e);
    });

    this.$anchorNavButton.on('click', (e) => {
      e.preventDefault();

      this.$anchorNav.toggleClass('expanded');
    });
  }

  resizeReinit() {
    if ($(window).width() > 1023) {
      this.closeAnchorNav();
    }

    this.$headerHeight = $('.site-header').outerHeight();
    this.$anchorNavPosition = this.$anchorNav.offset().top;
    this.$containerBottom =
      this.$anchorContent.offset().top + this.$selector.outerHeight();
    this.$anchorNavHeight = this.$anchorNav.outerHeight(true);
  }

  setupSticky() {
    let anchorNavPosition = this.$anchorNavPosition;

    $(window).on('scroll load', () => {
      let currentScroll = $(window).scrollTop() + this.$headerHeight;

      if (currentScroll >= anchorNavPosition) {
        this.$anchorNav.addClass('sticky');
        this.$anchorContentInfoWrapper.addClass('sticky');
        this.$anchorNav.css(
          'top',
          this.$headerHeight - 1
        );
        this.$anchorContentInfoWrapper.css(
          'top',
          this.$headerHeight + this.$anchorNavHeight + 40
        );
      }
      else {
        this.$anchorNav.removeClass('sticky');
        this.$anchorContentInfoWrapper.removeClass('sticky');
        this.$anchorNav.css('top', '');
        this.$anchorContentInfoWrapper.css('top', '');
      }
    });
  }

  setupAnchorLinks(e) {
    let spacer = $(window).width() >= 1024 ? 70 : 60;

    this.$anchorNavLinks.removeClass('active');
    $(e.currentTarget).addClass('active');

    let id = $(e.currentTarget).attr('href');
    let $target = $(id, this.$anchorContent);

    if ($target.length) {
      $('html, body').animate(
        {
          scrollTop:
            $target.offset().top -
            this.$headerHeight -
            spacer,
        },
        500
      );

      window.history.replaceState(null, window.title, `${id}`);
    }

    if (this.$anchorNav.hasClass('expanded')) {
      this.$anchorNav.removeClass('expanded');
    }
  }

  scrollToSection() {
    const hash = window.location.hash;
    if ($(hash).length) {
      let spacer = 60;
      if ($('.site-header__notificationbar.active').length) {
        
        const $notification = $('.site-header__notificationbar.notification-true.active');
        const $notifCarouselHeight = $notification.find('.carousel').outerHeight() + 30;
      
        if ($notifCarouselHeight > spacer) {
          spacer = $notifCarouselHeight;
        }
      }

      $("html, body").animate({
        scrollTop: $(hash).offset().top - this.$headerHeight - spacer,
      });
    }
  }

  setupScrollActive() {
    $(window).on('scroll load', () => {
      let spacer = $(window).width() >= 1024 ? 80 : 70;
      let currentScroll =
        $(window).scrollTop() +
        this.$headerHeight +
        spacer;

      this.$anchorContentDetailItem.each((i, ele) => {
        let targetTop = $(ele).offset().top;
        let targetBottom = targetTop + $(ele).outerHeight();
        let id = $(ele).attr('id');

        if (currentScroll >= targetTop && currentScroll < targetBottom) {
          this.$anchorNavLinks.removeClass('active');
          this.$anchorNavLinks.filter(`[href="#${id}"]`).addClass('active');
          this.updateDropdownLabel(id);
        }
        else if (
          currentScroll < this.$anchorContent.offset().top ||
          currentScroll > this.$containerBottom
        ) {
          this.$anchorNavLinks.removeClass('active');
          this.closeAnchorNav();
        }
      });
    });
  }

  initLabelText() {
    const initialLabelText = this.$anchorNavLinks.first().text();
    this.$anchorNavButton.find('.label').text(initialLabelText);
  }

  updateDropdownLabel(id) {
    const activeLinkText = this.$anchorNavLinks.filter(`[href="#${id}"]`).text();
    this.$anchorNavButton.find('.label').text(activeLinkText);
  }

  closeAnchorNav() {
    if (this.$anchorNav.hasClass('expanded')) {
      this.$anchorNav.removeClass('expanded');
    }
  }
}
